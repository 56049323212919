import React, { useEffect, useState } from 'react'
import './Service.css'
import data from './data.json'
import ContactFormular from '../../components/ContactFormular/ContactFormular'
import { Redirect } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const Service = () => {
    const [getImage, setImage] = useState(undefined)

    const isLittle = useMediaQuery({query: "(max-width: 1100px)"})
    
    
    const correctHeader = (header: string) => {
        if (header === "limousinenservice") return "Limousinenservice<br/>mit Sicherheitsbackground"
        return header[0].toUpperCase() + header.substr(1)
    }
    
    const query = window.location.search.substr(1)
    useEffect(() => {
        // loadImage
        import("../../assets/" + query + ".jpg")
            .then((module) => setImage(module.default))
    }, [query])

    if (!query || !Object.keys(data).includes(query)) {
        console.log('undefined service')
        return ( <Redirect to="/services" /> )
    }



    const header = correctHeader(query)


    return (
        <div
            className="Service"
        >
            <div
                    className="Service-header"
            >
                <img
                    src={getImage}
                    className="Service-header-image"
                    style={query === "training" ? {
                        objectFit: "contain"
                    } : {}}
                    alt="alt"
                />
                <h1
                    className="Service-header-text"
                    dangerouslySetInnerHTML={{__html: header}}
                />
            </div>
            <div
                className="Service-content"
            >
                <p
                    className="Service-text"
                >
                    {(data as unknown as any)[query]}
                </p>
                
                <div
                        className="Service-contactFormular-container"
                >
                    {!isLittle && <div
                        className="Service-contactFormular-information"
                    >
                        <h1>Unsere Kontaktdaten</h1>
                        Tel.: <a href="tel:+43 676 6440 434">+43 676 6440 434</a><br />
                        Mail: <a href="mailto:office@topprotection-security.at">office@topprotection-security.at</a>
                    </div>}
                    <div
                        className="Service-contactFormular"
                    >
                        <h1
                            className="Service-contactFormular-header"
                        >
                            Kontaktieren Sie uns !
                        </h1>
                        {isLittle && <div
                                className="Service-contactFormular-information"
                        >
                                Tel: <a href="tel:+43 664 1029 775">+43 664 1029 775</a><br />
                                Mail: <a href="mailto:office@topprotection-security.at">office@topprotection-security.at</a>
                            </div>
                        }
                        <ContactFormular/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service
